import { DOCUMENT } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  input,
  NgZone,
  numberAttribute,
  OnDestroy,
  signal,
  viewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  GAP,
  TOAST_WIDTH,
  TOAST_DURATION,
  VIEWPORT_OFFSET,
  VISIBLE_TOASTS_AMOUNT,
  ToastFilterPipe
} from './internals';
import {
  VmrThemeManager,
  VMR_DARK_THEME_CLASS,
  VMR_LIGHT_THEME_CLASS
} from '@vermeer-corp/it-ng-components/theme-manager';
import { VmrToast } from './toast.component';
import { VmrToastService } from './toast.service';
import { ToastPosition, type ToastOptions } from './types';
import { explicitEffect, isArrayWithLength, isNumber } from '@vermeer-corp/it-ng-components/core';

/**
 * `VmrToastContainer` is the parent component for all toasts. It should be rendered in
 * the root template of your application and accepts a variety of global options.
 *
 * See https://ui.vermeer.com/components/toast/examples.
 */
@Component({
  standalone: true,
  selector: 'vmr-toast-container',
  styleUrl: 'toast-container.component.scss',
  templateUrl: 'toast-container.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [VmrToast, ToastFilterPipe]
})
export class VmrToastContainer implements OnDestroy {
  private readonly _ngZone = inject(NgZone);
  private readonly _document = inject(DOCUMENT);
  private readonly _toastService = inject(VmrToastService);

  // TODO: look into better way to handle themes in this component
  private readonly _isDarkMode = inject(VmrThemeManager, {optional: true})?.isDarkMode;

  private _isFocusWithinRef = false;
  private _lastFocusedElementRef: HTMLElement | null = null;

  /** Toasts state value. */
  toasts = this._toastService.toasts;

  /** Toast heights state value - linked via toast id. */
  heights = this._toastService.heights;

  /**
   * Keyboard shortcut that will move focus to the toaster area.
   * @default 'undefined'
   */
  hotKey = input<string[]>(); // E.g. value of ['altKey', 'KeyT']

  /**
   * Offset from the edges of the screen.
   * @default '32px'
   */
  offset = input<string | number>(VIEWPORT_OFFSET);

  /**
   * These will act as default options for all toasts.
   * @default {}
   */
  toastOptions = input<ToastOptions | undefined>();

  /**
   * Place where the toasts will be rendered.
   * @default 'top-center'
   */
  position = input<ToastPosition>('top-center');

  /**
   * Toasts will be expanded by default.
   * @default false
   */
  expandByDefault = input(false, { transform: booleanAttribute });

  /**
   * Adds a close button to all toasts.
   * @default false
   */
  showCloseButton = input(false, { transform: booleanAttribute });

  /**
   * The duration of the toast in milliseconds.
   * @default 3500
   */
  duration = input(TOAST_DURATION, { transform: numberAttribute });

  /**
   * Max number of toasts visible on screen.
   * @default 3
   */
  visibleToasts = input(VISIBLE_TOASTS_AMOUNT, { transform: numberAttribute });

  /**
   * The class attribute added to the host element.
   * @default 'undefined'
   */
  _class = input<string | string[] | undefined>(undefined, { alias: 'class' });

  /** The expanded state for toasts. */
  expanded = signal(false);

  /** Value is true on mouseenter event for toast list element. */
  interacting = signal(false);

  /** Parent list element that wraps toasts. */
  listRef = viewChild<ElementRef<HTMLOListElement>>('listRef');

  /** Total count of toasts. */
  toastCount = computed<number>(() => this.toasts()?.length || 0);

  /** Current application session theme - vmr-light-mode or vmr-dark-mode. */
  themeClass = computed<string>(() => this._isDarkMode?.() ? VMR_DARK_THEME_CLASS : VMR_LIGHT_THEME_CLASS);

  /** Tracks all unique position values for all toasts. */
  possiblePositions = computed<ToastPosition[]>(() =>
    Array.from(
      new Set(
        [
          this.position(),
          ...(this.toasts()
            .filter(t => t.position)
            .map(t => t.position) as ToastPosition[])
        ].filter(Boolean)
      )
    )
  );

  /** CSS vars for parent OL element. */
  toastListCssVars = computed(() => ({
    '--gap': `${GAP}px`,
    '--width': `${TOAST_WIDTH}px`,
    '--front-toast-height': `${this.heights()[0]?.height}px`,
    '--offset': isNumber(this.offset()) ? `${this.offset()}px` : (this.offset() ?? `${VIEWPORT_OFFSET}`)
  }));

  constructor() {
    // Effect that monitors if there any active toasts or not.
    // Dynamically add/remove keydown event listener on the document (perf to reduce zone hits).
    effect(() => {
      const hotKey = this.hotKey();
      const toastCount = this.toastCount();

      if (toastCount > 1 && isArrayWithLength(hotKey)) {
        this._ngZone.runOutsideAngular(() => {
          this._document?.addEventListener('keydown', this._keyDownHandler);
        });
      } else {
        this._removeKeydownListener();
      }
    });

    explicitEffect(
      [this.toastCount],
      ([toastCount]) => {
        if (toastCount >= 1) {
          this.expanded.set(false);
        }
      },
      {defer: true}
    );
  }

  ngOnDestroy(): void {
    this._toastService.reset();
    this._removeKeydownListener();
  }

  /** Callback for mouseleave event bound to toast list element. */
  handleMouseLeave(): void {
    if (!this.interacting()) {
      this.expanded.set(false);
    }
  }

  handlePointerDown(e: MouseEvent) {
    if (!this._isNotDismissableEvent(e)) {
      this.interacting.set(true);
    }
  }

  /** Callback for focus event bound to toast list element. */
  handleFocus(e: FocusEvent): void {
    if (!this._isNotDismissableEvent(e) && !this._isFocusWithinRef) {
      this._isFocusWithinRef = true;
      this._lastFocusedElementRef = e.relatedTarget as HTMLElement;
    }
  }

  /** Callback for blur event bound to toast list element. */
  handleBlur(e: FocusEvent): void {
    if (
      this._isFocusWithinRef &&
      !(e.target as HTMLOListElement).contains(e.relatedTarget as HTMLElement)
    ) {
      this._isFocusWithinRef = false;
      if (this._lastFocusedElementRef) {
        this._lastFocusedElementRef.focus({ preventScroll: true });
        this._lastFocusedElementRef = null;
      }
    }
  }

  private _removeKeydownListener(): void {
    this._document?.removeEventListener('keydown', this._keyDownHandler);
  }

  private _isNotDismissableEvent(e: Event): boolean {
    return e.target instanceof HTMLElement && e.target.dataset['dismissible'] === 'false';
  }

  /**
   * Event handler is registered outside the zone, so must bring back in when necessary.
   * Should only run when toasts colleciton has 2 or more toasts so no need to check if expanded can be set true.
   */
  private _keyDownHandler = (e: KeyboardEvent): void => {
    const listEl = this.listRef()?.nativeElement;
    if (!listEl) {
      return;
    }

    const docEl = this._document?.activeElement;
    const isHotkeyPressed = !!this.hotKey()?.every(key => (e as never)[key] || e.code === key);

    if (isHotkeyPressed) {
      this._ngZone.run(() => {
        this.expanded.set(true);
        listEl.focus();
      });
    } else if (e.code === 'Escape' && (docEl === listEl || listEl.contains(docEl))) {
      this._ngZone.run(() => this.expanded.set(false));
    }
  };
}