/* eslint-disable @angular-eslint/component-selector */
import {
  input,
  inject,
  computed,
  Component,
  booleanAttribute,
  ViewEncapsulation,
  ChangeDetectionStrategy
} from '@angular/core';
import { VMR_GLOBAL_CONFIG } from '../providers';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import type { IconPrefix, IconProp } from '@fortawesome/fontawesome-svg-core';

/**
 * A wrapper component used to dynamically select the parent fontawesome tag (<fa-icon> | <i>).
 * Fleet is not using angular fontawesome and renders icons using <i> tags.
 */
@Component({
  standalone: true,
  selector: 'fa-icon-wrapper',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    @if (!_useIconTag) {
      <fa-icon
        [icon]="icon()"
        [class]="iconClass()"
        style="{{iconStyle()}}"
        [fixedWidth]="fixedWidth()"
      />
    } @else {
      <i
        style="{{iconStyle()}}"
        [class]="iconTagClass()"
      ></i>
    }
  `,
  imports: [FaIconComponent]
})
export class FaIconWrapperComponent {
  protected _useIconTag: boolean;
  readonly icon = input.required<IconProp>();
  readonly iconPrefix = input<IconPrefix>('fas');
  readonly iconStyle = input<string |undefined>(undefined);
  readonly iconClass = input<string | undefined>(undefined);
  readonly fixedWidth = input<boolean, unknown>(false, {transform: booleanAttribute});

  readonly iconTagClass = computed<string>(
    () => `${this.iconPrefix()} fa-${this.icon()} ${this.iconClass() || ''}`.trim()
  );

  constructor() {
    this._useIconTag = !!inject(VMR_GLOBAL_CONFIG, {optional: true})?.useIconTag;
  }
}