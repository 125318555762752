import { NgComponentOutlet } from '@angular/common';
import { VmrToastService } from './toast.service';
import { GAP, TIME_BEFORE_UNMOUNT, TOAST_DURATION } from './internals';
import { VmrProgressSpinner } from '@vermeer-corp/it-ng-components/loader';
import { explicitEffect, FaIconWrapperComponent, isString, toClassName } from '@vermeer-corp/it-ng-components/core';
import type { ToastOptions, ToastProps, ToastTypes } from './types';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  input,
  OnDestroy,
  signal,
  untracked,
  viewChild,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  afterNextRender
} from '@angular/core';

@Component({
  standalone: true,
  selector: 'vmr-toast',
  templateUrl: 'toast.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgComponentOutlet, FaIconWrapperComponent, VmrProgressSpinner]
})
export class VmrToast implements OnDestroy {
  readonly isString = isString;
  private readonly _toastService = inject(VmrToastService);

  private _remainingTime = 0;
  private _closeTimerStartTimeRef = 0;
  private _lastCloseTimerStartTimeRef = 0;
  private _timeoutId: undefined | ReturnType<typeof setTimeout>;
  private _unmountTimeoutId: undefined | ReturnType<typeof setTimeout>;

  readonly toasts = this._toastService.toasts;
  readonly heights = this._toastService.heights;

  toastOptions = input<ToastOptions>();
  duration = input<number>(TOAST_DURATION);
  showCloseButton = input.required<boolean>();
  toast = input.required<ToastProps['toast']>();
  index = input.required<ToastProps['index']>();
  expanded = input.required<ToastProps['expanded']>();
  position = input.required<ToastProps['position']>();
  interacting = input.required<ToastProps['interacting']>();
  visibleToasts = input.required<ToastProps['visibleToasts']>();
  expandByDefault = input.required<ToastProps['expandByDefault']>();

  readonly mounted = signal(false);
  readonly initialHeight = signal(0);
  readonly offsetBeforeRemove = signal<number>(0);
  readonly markedForDelete = signal<boolean>(false);
  readonly toastRef = viewChild.required<ElementRef<HTMLLIElement>>('toastRef');

  readonly type = computed<ToastTypes>(() => this.toast().type);
  readonly toastId = computed<number | string>(() => this.toast().id);
  readonly yCoords = computed<string>(() => this.position().split('-')[0]);
  readonly isToastVisible = computed<boolean>(() => this.index() + 1 <= this.visibleToasts());

  readonly toastClasses = computed<string>(() =>
    toClassName(
      untracked(this.toast).class,
      this.toastOptions()?.class,
      `vmr-toast-type-${this.type()}`
    )
  );

  readonly faIconType = computed<IconProp>(() => {
    switch (this.type()) {
      case 'info': return 'info-circle';
      case 'error': return 'xmark-circle';
      case 'success': return'check-circle';
      case 'warning': return 'exclamation-triangle';
      default: return 'info-circle';
    }
  });

  readonly toastStyle = computed(() => ({
    '--index': `${this.index()}`,
    '--toasts-before': `${this.index()}`,
    '--z-index': `${this.toasts().length - this.index()}`,
    '--initial-height': this.expandByDefault() ? 'auto' : `${this.initialHeight()}px`,
    '--offset': `${this.markedForDelete() ? this.offsetBeforeRemove() : this.offset()}px`,
    ...this.toastOptions()?.style
  }));

  readonly offset = computed<number>(() => {
    const heights = this.heights();
    const heightIdx = heights.findIndex(h => h.toastId === this.toastId()) || 0;
    const toastsHeightBefore = heights.reduce((prev, cur, curIdx) => (curIdx >= heightIdx) ? prev : (prev + cur.height), 0);
    return Math.round((heightIdx * GAP) + toastsHeightBefore);
  });

  constructor() {
    effect((onCleanup) => {
      const expanded = this.expanded();
      const interacting = this.interacting();

      untracked(() => {
        if (this.duration() !== Number.POSITIVE_INFINITY) {
          (expanded || interacting)
            ? this.pauseTimer()
            : this.startTimer();
        }
      });

      onCleanup(() => this._checkClearTimeout());
    });

    // if the toast has been updated after the initial render, we want to reset the timer and set the remaining time to the new duration
    // else if toast has been marked for delete, then we start delete process
    explicitEffect(
      [this.toast],
      ([toast]) => {
        if (toast.updated) {
          this._checkClearTimeout();
          this._remainingTime = this.duration();
          this.startTimer();
        } else if (toast.delete) {
          this.deleteToast();
        }
      },
      {defer: true}
    );

    afterNextRender(() => this._initToastSetup());
  }

  ngOnDestroy(): void {
    this._checkClearTimeout();
    this._unmountTimeoutId && clearTimeout(this._unmountTimeoutId);
    this._toastService.removeHeight(this.toastId());
  }

  deleteToast(): void {
    this.markedForDelete.set(true);
    this.offsetBeforeRemove.set(this.offset());
    this._toastService.removeHeight(this.toastId());
    this._unmountTimeoutId = setTimeout(() => this._toastService.dismiss(this.toastId()), TIME_BEFORE_UNMOUNT);
  }

  /**
   * Pause the timer on each hover.
   * If toast's duration changes, it will be out of sync w/ remainingAtTimeout, so we need to restart the timer with the new duration.
   */
  pauseTimer(): void {
    if (this._lastCloseTimerStartTimeRef < this._closeTimerStartTimeRef) {
      const elapsedTime = new Date().getTime() - this._closeTimerStartTimeRef;
      this._remainingTime = this._remainingTime - elapsedTime;
    }

    this._lastCloseTimerStartTimeRef = new Date().getTime();
  }

  startTimer(): void {
    this._closeTimerStartTimeRef = new Date().getTime();

    this._timeoutId = setTimeout(() => {
      this.toast().onAutoClose?.(this.toast());
      this.deleteToast();
    }, this._remainingTime);
  }

  onCloseButtonClick(): void {
    const { dismissible, onDismiss } = this.toast();
    if (dismissible) {
      this.deleteToast();
      onDismiss?.(this.toast());
    }
  }

  onCancelClick(): void {
    if (this.toast().dismissible) {
      this.deleteToast();
      this.toast().cancel?.onClick?.();
    }
  }

  onActionClick(event: MouseEvent): void {
    if (!event.defaultPrevented) {
      this.deleteToast();
      this.toast().action?.onClick(event);
    }
  }

  private _checkClearTimeout(): void {
    if (this._timeoutId) {
      clearTimeout(this._timeoutId);
    }
  }

  private _initToastSetup(): void {
    this._remainingTime = this.duration();
    this.mounted.set(true);
    const height = this.toastRef().nativeElement.getBoundingClientRect().height;
    this.initialHeight.set(height);
    this._toastService.addHeight({ height, toastId: this.toastId() });
  }
}