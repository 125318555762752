import type { Router } from '@angular/router';

export const getNormalizedBaseUrl = (
  url: string,
  router: Router,
  removeTrailing = true
): string => {
  const route = router.parseUrl(url);
  route.fragment = null;
  route.queryParams = {};

  const routeStr = route.toString();

  return removeTrailing ? removeTrailingSlash(routeStr) : routeStr;
};

export const removeTrailingSlash = (url: string): string => url.endsWith('/') ? url.slice(0, -1) : url;