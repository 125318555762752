/** EXTRA-SMALL: 320px */
export const XS = 320;

/** SMALL: 576px */
export const SM = 576;

/** MEDIUM: 768px */
export const MD = 768;

/** LARGE: 992px */
export const LG = 992;

/** EXTRA-LARGE: 1200px */
export const XL = 1200;

/** EXTRA-EXTRA-LARGE: 1400px */
export const XXL = 1400;

/**
 * Breakpoints from Bootstrap v4 & v5
 * @see https://getbootstrap.com/docs/5.3/layout/breakpoints
 */
export const VmrBreakpoints = {
  XS,
  SM,
  MD,
  LG,
  XL,
  XXL
};