<li
  #toastRef
  tabindex="0"
  role="status"
  class="vmr-toast"
  aria-atomic="true"
  aria-live="polite"
  [style]="toastStyle()"
  [class]="toastClasses()"
  [class.vmr-toast-mounted]="mounted()"
  [class.vmr-toast-first]="index() === 0"
  [class.vmr-toast-visible]="isToastVisible()"
  [class.vmr-toast-deleted]="markedForDelete()"
  [class.vmr-toast-expanded]="expanded() || (expandByDefault() && mounted())"
  [attr.data-y-position]="yCoords()"
  [attr.data-dismissible]="toast().dismissible"
>
  @if (showCloseButton()) {
    <button
      aria-label="Close toast"
      class="vmr-toast-button-close"
      (click)="onCloseButtonClick()"
    >
      <fa-icon-wrapper icon="xmark" iconClass="vmr-icon" />
    </button>
  }

  @if (toast().component) {
    <ng-container *ngComponentOutlet="toast().component!; inputs: toast().componentProps" />
  } @else {
    @if (type() !== 'default' || toast().icon) {
      <div class="vmr-toast-icon">
        @if (type() === 'loading' && !toast().icon) {
          @if (isToastVisible()) {
            <vmr-progress-spinner color="white" diameter="22" />
          }
        } @else if (toast().icon) {
          <ng-container *ngComponentOutlet="toast().icon!; inputs: toast().componentProps" />
        } @else {
          <fa-icon-wrapper [icon]="faIconType()" iconClass="vmr-icon" />
        }
      </div>
    }

    <div class="vmr-toast-content">
      @if (toast().title; as title) {
        <div class="vmr-toast-title">
          @if (isString(title)) {
            {{ title }}
          } @else {
            <ng-container *ngComponentOutlet="title; inputs: toast().componentProps" />
          }
        </div>
      }

      @if (toast().description; as description) {
        <div class="vmr-toast-description">
          @if (isString(description)) {
            {{ description }}
          } @else {
            <ng-container *ngComponentOutlet="description; inputs: toast().componentProps" />
          }
        </div>
      }
    </div>

    @if (toast().cancel; as cancel) {
      <button
        class="vmr-toast-button vmr-toast-button-cancel"
        (click)="onCancelClick()"
        [style]="toast().cancelButtonStyle ?? toastOptions()?.cancelButtonStyle"
      >
        {{ cancel.label }}
      </button>
    }

    @if (toast().action; as action) {
      <button
        class="vmr-toast-button"
        (click)="onActionClick($event)"
        [style]="toast().actionButtonStyle ?? toastOptions()?.actionButtonStyle"
      >
        {{ action.label }}
      </button>
    }
  }
</li>