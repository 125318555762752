import { cssUnitAttribute } from '../coercion';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  model,
  OnInit,
} from '@angular/core';

/**
 * Extend this when creating a form component that needs to bind to `formControlName` => `controlName`.
 * Allows for passing a `FromControl` via the `controller` model input for backwards compat.
 */
@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders:[{provide: ControlContainer, useExisting: FormGroupDirective}],
  host: {
    '[class.vmr-hide-subscript-wrapper]': 'hideSubscript()'
  }
})
export abstract class WrappedFormField implements OnInit {
  #parentFormGroup = inject(FormGroupDirective, {optional: true});

  /**
   * `FormControl` for the input.
   * This will always need to be defined, however, consumers have 2 options for setting it:
   * 1). Pass `FormControl` directly to this property.
   * 2). Pass `controlName` (`formControlName`) and allow `controller` to be set via parent `FormGroupDirective`.
   */
  readonly controller = model<FormControl<any>>();

  /**
   * This is the same as Angular's `formControlName`.
   * Can be used to bind a `FormControl` to the underlying input element.
   * If this is not specificied, a `FormControl` needs to be passed to the `controller` input.
   */
  readonly controlName = input<string | undefined>();

  /** Class that will be applied to the parent `mat-form-field` element. Defaults to `undefined`. */
  readonly formFieldClass = input<string | undefined>();

  /**
   * Whether or not to apply a class that sets the parent `mat-form-field` element subscript to `display: none`.
   * Defaults to `false`.
   */
  readonly hideSubscript = input<boolean, unknown>(false, {
    transform: booleanAttribute
  });

  /**
   * Quick way to directly set the parent `mat-form-field` element width via `style.width`.
   * Defaults to `null`.
   */
  readonly formFieldWidth = input<string | null, string | number | undefined | null>(null, {
    transform: cssUnitAttribute
  });

  ngOnInit(): void {
    if (!this.controller()) {
      this.controller.set(
        this.#parentFormGroup?.form.get(this.controlName() || '') as FormControl ?? new FormControl()
      );
    }
  }
}