import { Pipe, type PipeTransform, inject } from '@angular/core';
import { DomSanitizer, type SafeHtml } from '@angular/platform-browser';

@Pipe({
  standalone: true,
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {
  private readonly _sanitize = inject(DomSanitizer);

  transform(value: string): SafeHtml {
    return this._sanitize.bypassSecurityTrustHtml(value);
  }
}