import { isNumber } from '../helpers';

/**
 * Coerces a value to a CSS pixel/rem/em value.
 */
export const cssUnitAttribute = (value?: string | number | null): string | null => {
  if (value == null) {
    return null;
  }

  return (isNumber(value) || !isNaN(value.charAt(value.length - 1) as any))
    ? `${value}px`
    : value;
};