import type { VmrProgressColor } from './types';
import { NOOP_ANIMATION_MODE } from '@vermeer-corp/it-ng-components/core';
import {
  input,
  inject,
  Component,
  InjectionToken,
  numberAttribute,
  ViewEncapsulation,
  ANIMATION_MODULE_TYPE,
  ChangeDetectionStrategy,
} from '@angular/core';

/** Default `VmrProgressSpinner` options that can be overridden. */
export interface VmrProgressSpinnerDefaultOptions {
  /** Diameter of the spinner. */
  diameter?: number;
  /** Width of the spinner's stroke. */
  strokeWidth?: number;
  /** Duration of the rotate animation. */
  animationDuratuin?: string;
  /** Default theme color of the progress spinner. */
  color?: VmrProgressColor;
}

/** Injection token to be used to override the default options for `VmrProgressSpinner`. */
export const VMR_PROGRESS_SPINNER_DEFAULT_OPTIONS = new InjectionToken<VmrProgressSpinnerDefaultOptions>(
  'VMR_PROGRESS_SPINNER_DEFAULT_OPTIONS',
);

/** Base reference size of the spinner. */
const BASE_SIZE = 85;

/** Base reference stroke width of the spinner. */
const BASE_STROKE_WIDTH = 5;

/**
 * A circular progress-spinner for indicating progress and activity.
 * See https://ui.vermeer.com/components/loader/examples.
 *
 * @example
 * ```html
 * <vmr-progress-spinner color="accent" strokeWidth="4" />
 * ```
 */
@Component({
  standalone: true,
  selector: 'vmr-progress-spinner',
  styleUrl: 'progress-spinner.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <svg
      viewBox="25 25 50 50"
      class="vmr-progress-spinner-svg"
      [style.animation-duration]="animationDuration()"
    >
      <circle
        r="20" cx="50" cy="50"
        stroke-miterlimit="10"
        [attr.fill]="trackColor()"
        [attr.stroke-width]="strokeWidth()"
        class="vmr-progress-spinner-circle"
      />
    </svg>
  `,
  host: {
    'tabindex': '-1',
    'role': 'progressbar',
    'aria-valuemin': '0',
    'aria-valuemax': '100',
    'class': 'vmr-progress-spinner',
    '[class]': '"mat-" + color()',
    '[class.vmr-noop-animation]': '_animationsDisabled',
    '[style.width.px]': 'diameter()',
    '[style.height.px]': 'diameter()'
  }
})
export class VmrProgressSpinner {
  private readonly _defaults = inject<VmrProgressSpinnerDefaultOptions>(VMR_PROGRESS_SPINNER_DEFAULT_OPTIONS, {optional: true});

  /** @internal */
  protected _animationsDisabled: boolean;

  /** Color for the background of the circle. Defaults to `none`. */
  readonly trackColor = input<string>('none');

  /** Theme color palette for the component. Defaults to `primary`. */
  readonly color = input<VmrProgressColor>(this._defaults?.color || 'primary');

  /** Duration of the rotate animation. Defaults to `1.5s`. */
  readonly animationDuration = input<string>(this._defaults?.animationDuratuin || '1.5s');

  /** The diameter of the progress spinner (will set width and height of svg). Defaults to `85`. */
  readonly diameter = input<number, unknown>(this._defaults?.diameter || BASE_SIZE, {
    transform: numberAttribute
  });

  /** Width of the progress spinner circle stroke. Defaults to `5`. */
  readonly strokeWidth = input<number, unknown>(this._defaults?.strokeWidth || BASE_STROKE_WIDTH, {
    transform: numberAttribute
  });

  constructor() {
    this._animationsDisabled = inject(ANIMATION_MODULE_TYPE, {optional: true}) === NOOP_ANIMATION_MODE;
  }
}