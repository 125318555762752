@if (toastCount() > 0) {
  <section
    tabindex="-1"
    [class]="themeClass()"
    id="vmr-toast-container"
  >
    @for (pos of possiblePositions(); track pos) {
      <ol
        #listRef
        tabindex="-1"
        [class]="_class()"
        [style]="toastListCssVars()"
        class="vmr-toast-ordered-list"
        (blur)="handleBlur($event)"
        (focus)="handleFocus($event)"
        (mousemove)="expanded.set(true)"
        (mouseenter)="expanded.set(true)"
        (mouseleave)="handleMouseLeave()"
        (pointerup)="interacting.set(false)"
        (pointerdown)="handlePointerDown($event)"
        [attr.data-y-position]="pos.split('-')[0]"
        [attr.data-x-position]="pos.split('-')[1]"
      >
        @for (toast of toasts() | toastFilter: $index : pos; track toast.id) {
          <vmr-toast
            [toast]="toast"
            [index]="$index"
            [interacting]="interacting()"
            [toastOptions]="toastOptions()"
            [visibleToasts]="visibleToasts()"
            [expandByDefault]="expandByDefault()"
            [position]="toast.position ?? position()"
            [expanded]="expanded()"
            [showCloseButton]="showCloseButton() || !!toast.showCloseButton"
            [duration]="toast.duration ?? toastOptions()?.duration ?? duration()"
          />
        }
      </ol>
    }
  </section>
}