/** Max visible toasts amount */
export const VISIBLE_TOASTS_AMOUNT = 3;

/** Viewport padding */
export const VIEWPORT_OFFSET = '24px';

/** Default duration of a toasts (in ms) */
export const TOAST_DURATION = 3500;

/** Default toast width */
export const TOAST_WIDTH = 365;

/** Default gap between toasts */
export const GAP = 12;

/** Delay for exit animation duration (starts after deleted) */
export const TIME_BEFORE_UNMOUNT = 200;